import { onErrorCaptured } from 'vue';
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';

export default function useGridErrorHandler() {

    const gridControl = useBaseGridControl();

    onErrorCaptured((error: unknown, instance: any, info: string) => {
        let gridInfo: {
            id: string;
        };
        try {
            gridInfo = {
                id: gridControl.value.id,
            };
        } catch (_) {
            gridInfo = {
                id: 'Unkown',
            }
        }
        console.group(`${gridInfo.id} grid error`);
            console.error(error);
            console.log('Instance', instance);
            console.info(`Error info: ${info}`);
        console.groupEnd();
    });
}